@tailwind base;
@tailwind components;
@tailwind utilities;

.red-shadow-dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 3px red;
}

.green-shadow-dot {
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px green;
}

.bg-space-image {
  background: url("../public/assets/images/bg-space.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* scrollbar styling */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  width: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4d5358c5;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
